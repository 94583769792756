<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">{{$t('snapshot.title')}} ({{rows.length}})</h6>
		</template>
		<a-button type="primary" slot="extra" size="small" @click="createSnapshot">{{$t('snapshot.btnCreate')}}</a-button>
		<CardProcess @snapshotReload="getData" />
		<a-row :gutter="[24, 24]" v-if="!fetchListing">
			<a-col :span="24" v-for="row in rows">
				<a-card :bordered="false" class="card-billing-info">
					<a-badge v-if="row.status === SNAPSHOT_ACTIVE" status="success" />
					<a-badge v-else status="default" />
					<div class="col-info">
						<a-descriptions :title="row.name" :column="1">
							<a-descriptions-item v-if="_.get(row, 'volume.name')" label="Volume">
								{{_.get(row, 'volume.name')}}
							</a-descriptions-item>
							<a-descriptions-item :label="$t('snapshot.size')">
								{{row.size}} {{STORAGE_UNIT}} {{row.volume.volume_type.toUpperCase()}}
							</a-descriptions-item>
							<a-descriptions-item :label="$t('snapshot.created')">
								{{row.created | moment("HH:mm DD-MM-YYYY")}}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="col-action">
						<a-dropdown placement="bottomRight">
							<a-menu slot="overlay" @click="selectAction">
								<a-menu-item key="delete" class="text-danger" :value="{id: row.id, name: row.name}"><a-badge status="error" /> {{$t('volume.list.delete')}}</a-menu-item>
							</a-menu>
							<a-button size="small"> {{$t('action')}} <a-icon type="down" /> </a-button>
						</a-dropdown>
					</div>
				</a-card>
			</a-col>
			<a-empty v-if="_.isEmpty(rows)" />
		</a-row>
		<a-spin v-else tip="Loading..."></a-spin>
	</a-card>
</template>

<script>
	import listing from "@/services/snapshot/listing"
	import deleteSnapshot from "@/services/snapshot/delete"
	import retrieve from "@/services/retrieve/create"
	import {SNAPSHOT_ACTIVE, STORAGE_UNIT, ORDER_TYPE_SNAPSHOT} from "@/constant"
	import { notification } from 'ant-design-vue'
	import CardProcess from "@/components/Cards/CardProcess"
	
	export default ({
		components: {
			CardProcess,
		},
		data() {
			return {
				fetchListing: true,
				rows: [],
				SNAPSHOT_ACTIVE,
				STORAGE_UNIT
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.fetchListing = true
				listing()
				.then(res=> {
					this.fetchListing = false
					if (this._.isArray(res)) {
						this.rows = res
					}
				})
			},
			createSnapshot() {
				this.$router.push({name: 'snapshot.create'})
			},
			selectAction(e) {
				const value = e.item.value
				switch(e.key) {
					case 'delete':
						const h = this.$createElement
						this.$confirm({
							title: `${this.$t('snapshot.actionDelete.title')} [${value.name}]`,
							content: h('div', {}, [
								h('p', this.$t('snapshot.actionDelete.content')),
								h('b', `[${value.name}]`),
							]),
							okText: this.$t('snapshot.actionDelete.btnCreate'),
							okType: 'danger',
							cancelText: this.$t('popup.cancel'),
							onOk: async ()=> {
								const res = await retrieve({
									reference_ids : [value.id],
									type : ORDER_TYPE_SNAPSHOT
								})
								if (this._.isObject(res)) {
									notification.success({
										message: this.$t('notify.success.deleteSnapshot')
									})
								}
								// const res = await deleteSnapshot(value.id)
								// if (this._.isObject(res)) {
								// 	notification.success({
								// 		message: this.$t('notify.success.deleteSnapshot')
								// 	})
								// }
								// this.getData()
							}
						});
					break;
				}
			}
		}
	})

</script>